<template>
    <div>
        <div class="screenWidth">
            <currentLocation :sibItem="sibItem"></currentLocation>
            <div class="box">
                <div class="left">
                    <div class="title">{{ info.title }}</div>
                    <div class="title_one">Views:{{ info.click_num }}&nbsp;&nbsp;&nbsp;&nbsp;Publish Time: {{ info.
                            createtime }}</div>
                    <div class="Rich_text">
                        <div v-html="info.content"></div>
                    </div>
                </div>
                <div class="right">
                    <div class="Related_box">
                        <div class="title">
                            Related Reading
                        </div>
                        <ul>
                            <li @click="qiehaun(item.id)" v-for="(item, index) in newListtab" :key="index">
                                <span></span>
                                <div>{{ item.title }}</div>
                            </li>
                        </ul>
                    </div>

                    <div class="Contact_box">
                        <div class="Contact_title">Contact Us and Get Free Samples</div>
                        <input v-model="name" class="input_box" type="text" placeholder="Name">
                        <input @input="handleInput(1)" v-model="email" @blur="checkInput('email')"
                            :class="{ 'input_box': true, 'active': inputshowone }" type="text" placeholder="*Email">
                        <div v-if="inputshowone" class="Tips">This field is required</div>
                        <input @input="handleInput(2)" v-model="tel" @blur="checkInput('tel')"
                            :class="{ 'input_box': true, 'active': inputshowtow }" type="text"
                            placeholder="*Tel/Whatsapp">
                        <div v-if="inputshowtow" class="Tips">This field is required</div>
                        <textarea @input="handleInput(3)" v-model="Description" @blur="checkInput('Description')"
                            :class="{ 'input_box': true, 'active': inputshowthree }" placeholder="*Message"></textarea>
                        <div v-if="inputshowthree" class="Tips">This field is required</div>

                        <button @click="settijiao">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            sibItem: [
                {
                    title: "Home",
                    path: "/",
                },
                {
                    title: "News",
                    path: "/news",
                },
                {
                    title: "NewsDetail",
                    path: "",
                },
            ],
            inputshowone: false,
            inputshowtow: false,
            inputshowthree: false,

            name: '',
            email: '',
            tel: '',
            Description: '',
            detailId: '',//详情id
            newListtab: [],//新闻列表
            info: '',//新闻详情
        }
    },
    mounted() {
        let detailId = this.$route.query.id
        console.log(detailId, '新闻详情页面接收参数');
        this.detailId = this.$route.query.id
        this.getnewDetail()
    },
    methods: {
        /**
        * 新闻切换
        * 刘义博
        * 2024-7-20
        */
        qiehaun(id) {
            this.detailId = id
            this.getnewDetail()
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // 平滑滚动效果
            })
        },
        /**
        * 提交联系我们
        * 刘义博
        * 2024-7-20
        */
        settijiao() {
            if (this.email == "") {
                this.$message({
                    message: 'Please fill in yo r email address',
                    type: 'warning',
                    offset: 100
                });
                return
            } else if (this.tel == "") {
                this.$message({
                    message: 'Please fill in your phone number or Whatsapp account',
                    type: 'warning',
                    offset: 100
                });
                return
            } else if (this.Description == "") {
                this.$message({
                    message: 'Please leave us a message to understand your needs',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            this.$request({
                url: "/Index/feedBack",
                data: {
                    name: this.name,
                    mobile: this.tel,
                    email: this.email,
                    message: this.Description,
                }
            }).then((res) => {
                console.log("提交成功", res);
                if (res.code == 1) {
                    this.$message({
                        message: res.msg,
                        type: 'success',
                        offset: 100
                    });
                    this.name = ""
                    this.email = ""
                    this.tel = ""
                    this.Description = ""
                }
            }).catch(err => {
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    offset: 100
                });
            })
        },
        /**
        * 获取详情数据
        * 刘义博
        * 2024-7-20
        */
        getnewDetail() {
            console.log('触发了');
            this.$request({
                url: "/Index/newsInfo",
                data: {
                    id: this.detailId
                }
            }).then((res) => {
                this.info = res.data.news_info
                this.newListtab = res.data.news_list
                this.info.content = this.info.content.replace(/<img/g, "<img style='max-width:100%;height:auto;'")
                console.log("新闻详情", res);
            });
        },
        handleInput(index) {
            if (index == 1) {
                this.inputshowone = false
            } else if (index == 2) {
                this.inputshowtow = false
            } else if (index == 3) {
                this.inputshowthree = false
            }
        },
        checkInput(field) {
            if (field === 'email' && this.email == '') {
                this.inputshowone = true // 显示提示信息如果email为空
            } else if (field === 'tel' && this.tel == "") {
                this.inputshowtow = true; // 显示提示信息如果tel为空
            } else if (field === 'Description' && this.Description == '') {
                this.inputshowthree = true
            }
        }
    },
    watch: {},
    computed: {},
    filters: {},
    components: {}
}
</script>
<style scoped lang="less">
@media (min-width: 1px) and (max-width: 767px) {
    .box {
        display: flex;
        flex-direction: column;

        .left {
            width: 100% !important;
            border-bottom: 1px solid #ddd;
            padding-bottom: 200px;

            .title {
                width: 100% !important;
                display: block !important;
                text-align: center !important;
                font-size: 120px !important;
                line-height: 160px !important;
                margin: 0 auto;
                padding: 150px 0 0 0 !important;
            }

            .title_one {
                width: 70%;
                margin: 0 auto;
                text-align: center !important;
                font-size: 70px !important;
                margin-top: 30px;
                line-height: 120px !important;
            }

            .Rich_text {
                font-size: 80px !important;
                line-height: 130px !important;
                margin-top: 120px !important;
            }
        }

        .right {
            width: 90% !important;
            margin: 0 auto;
            margin-top: 100px;

            .title {
                font-size: 100px !important;
                padding-bottom: 50px !important;
            }

            .Related_box {
                padding: 0 !important;
                padding-bottom: 50px !important;
            }

            .Related_box ul li {
                width: 100% !important;
                display: flex;
                align-items: center;
                line-height: 130px !important;

                div {
                    font-size: 70px !important;

                }
            }

            .Contact_box {
                display: none;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .box {
        display: flex;
        flex-direction: column;

        .left {
            width: 100% !important;
            border-bottom: 1px solid #ddd;
            padding-bottom: 200px;

            .title {
                width: 80%;
                display: block !important;
                text-align: center !important;
                font-size: 50px !important;
                margin: 0 auto;
            }

            .title_one {
                text-align: center !important;
                font-size: 30px !important;
                margin-top: 30px;
            }

            .Rich_text {
                font-size: 30px !important;
                line-height: 50px !important;

            }
        }

        .right {
            width: 80% !important;
            margin: 0 auto;
            margin-top: 50px;

            .title {
                font-size: 50px !important;
            }

            .Related_box {
                padding: 0 !important;
            }

            .Related_box ul li {
                width: 100% !important;
                display: flex;
                align-items: center;
                line-height: 60px !important;

                div {
                    font-size: 30px !important;

                }
            }

            .Contact_box {
                display: none;
            }
        }
    }
}

.screenWidth {
    padding-bottom: 100px;
}

.box {
    display: flex;
    // justify-content: space-between;

    .left {
        width: 50%;

        .title {
            width: 100%;
            font-size: 35px;
            font-weight: 600;
            text-align: center;
            color: #333333;
            word-break: break-word !important; 
            line-height: 50px;
        }

        .title_one {
            font-size: 15px;
            text-align: center;
        }

        .Rich_text {
            margin-top: 30px;
            line-height: 25px;
        }
    }

    .right {
        padding-left: 100px;
        width: 38%;

        .Related_box {
            padding-bottom:15px;
            border-bottom: 2px solid #BA0003;

            .title {
                font-weight: bold;
                font-size: 20px;
                border-bottom: 1px solid #ddd;
                padding-bottom: 10px;
            }

            ul li:hover {
                div {
                    color: #BA0003;
                }
            }

            ul li {
                display: flex;
                align-items: center;
                margin-top: 15px;
                line-height: 15px;
                cursor: pointer;

                span {
                    width: 3px;
                    height: 3px;
                    border-radius: 50%;
                    background: #ccc;
                    margin-right: 5px;
                }

                div {
                    font-size: 15px;
                    text-align: justify;
                    word-wrap: break-word;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                }
            }
        }

        .Contact_box {
            position: sticky;
            top: 20vh;
            margin-top: 100px;
            line-height: 50px;
            .Contact_title{
                font-size: 20px;
                font-weight: bold;
            }

            .input_box {
                color: rgb(0, 0, 0);
                width: 73%;
                padding: 10px 0;
                text-indent: 6px;
                box-sizing: content-box;
                border-radius: 5px;
                border: 1px solid #ccc !important;
                box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1) inset;
                margin-bottom: 10px;
                font-size: 20px !important;
                font: -webkit-mini-control !important;
            }

            .input_box:focus {
                outline: none;
            }

            textarea {
                min-height: 100px;
                font-size: 24px !important;
                font-weight: bold;
                resize: vertical;
                margin: 0 !important;
            }

            .Tips {
                font-size: 13px;
                color: red;
                margin-top: -20px;
            }

            button {
                display: flex;
                background: #BA0003;
                color: #fff;
                border: 0;
                padding: 10px 30px;
                border-radius: 2px;
                margin-top: -10px;
                font: -webkit-mini-control !important;
                cursor: pointer;
            }

            .active {
                background-color: rgba(255, 0, 0, 0.1) !important;
            }
        }
    }
}
</style>